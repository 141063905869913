import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["recipients", "deliveryMethod"];

  connect() {
    this.toggleRecipients();
  }

  toggleRecipients() {
    const isEmail = this.deliveryMethodTarget.value === "email";
    this.recipientsTarget.style.display = isEmail ? "block" : "none";
  }

  change() {
    this.toggleRecipients();
  }
}
