import { application } from './application'

import StripeController from "./stripe_controller"
application.register("stripe", StripeController)

import AdminPaymenttypeToggleController from './admin_paymenttype_toggle_controller'
application.register('admin_paymenttype_toggle', AdminPaymenttypeToggleController)

import NewsletterController from './newsletter_controller'
application.register('newsletter', NewsletterController)
